export const isUserLogged = document.cookie.includes('user_token') && document.cookie.includes('user_hash');
export const isCookiesAccepted = '';
export const redirectDefaultUrl = 'https://builddesygner.xyz/create/templates';
export const redirectFromRoyaltyImages = 'https://builddesygner.xyz/create/marketplace/';
export const redirectFromPDFUrl = 'https://builddesygner.xyz/create/templates';
export const alreadyRedirected = sessionStorage.getItem('alreadyRedirected');
export const isOtherMobile = /webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
export const isAndroid = /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
export const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
export const iOSSafari = navigator.userAgent.includes('Macintosh') && navigator.userAgent.includes('Safari');
